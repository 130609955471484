import { useState, useCallback } from "react";

import {   Image,  Grid, Segment, Table,  Header, Label, List,  Item, TableRow, TableCell, GridRow, GridColumn } from "semantic-ui-react";
import {config} from '../../Config'
import ImageViewer from 'react-simple-image-viewer';
import { formatURL } from "../../services/urlutils";



const OrgCultureCard = (props) => {
  console.log('RENDER OrgCultureCard')

  const customer = props.customer

  const mywidth = 5
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);



  const openImageViewer = useCallback((index) => {
    console.log('click')
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };



  
console.log('talent', customer)
  return customer ?  (

      <div>

      <Header as='h2'>
        <Image src={ `${config.url.API_BASE_URL}/customers/${customer.customerid}/image/logo`}  />  {customer.name}
      </Header>

      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='red' ribbon>
                Overview
              </Label>      


              <Table fixed>
                <Table.Row>
                  <Table.Cell>
                    <b>Country</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.country}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Region</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.region}
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>City</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.city}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>CEO</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.ceo}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>Year of establishment</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.establishmentdate}
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>
          </Grid.Column>

          <Grid.Column>
          <Item>
                        <Item.Content>
                        <Item.Meta><b>About</b></Item.Meta>
                        <Item.Description>
                      {customer.history}
                      </Item.Description>
                      </Item.Content>
                      </Item>   
          </Grid.Column>


          <Grid.Column>
          <Item>
                        <Item.Content>
                        <Item.Meta><b>Achievements</b></Item.Meta>
                        <Item.Description>
                      {customer.achievements}
                      </Item.Description>
                      </Item.Content>
                      </Item>                          

          </Grid.Column>        
        </Grid.Row>

        <Grid.Row >
          <Grid.Column colspan={2}  >
          <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='yellow' ribbon>
                Facility
              </Label>      

              <Table fixed>
                <Table.Row>
                  <Table.Cell>
                    <b>Name</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.facilityname}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Capacity</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.facilitycapacity} 
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>Average ticket holders</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.facilityholders} 
                    </Table.Cell>                    
                </Table.Row>                                


                <Table.Row>
                  <Table.Cell colspan={2}>
                  <Image 
                    src={ `${config.url.API_BASE_URL}/customers/${customer.customerid}/image/facility`} size='small'  
                    onClick={ () => openImageViewer(0) } 
                    style={{cursor:'pointer'}}
                    />  

                  {isViewerOpen && (
                                  <ImageViewer
                                    src={[`${config.url.API_BASE_URL}/customers/${customer.customerid}/image/facility`]}
                                    currentIndex={ currentImage }
                                    disableScroll={ false }
                                    closeOnClickOutside={ true }
                                    onClose={ closeImageViewer }
                                  />
                                )}                                              
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>
              </Grid.Column>
              </Grid.Row>

              <Grid.Row>
        <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='purple' ribbon>
                TV - Audience
              </Label>      
              <Table>
                {customer.tvbroadcasting==='no' &&
                <TableRow>
                  <TableCell>
                  No TV Audience found
                  </TableCell>

                </TableRow>
                }

                {customer.tvbroadcasting==='yes' &&
                <div>
                <TableRow>
                  <TableCell>
                  <b>Number of televised events</b>
                  </TableCell>
                  <TableCell>
                  {customer.tvevents}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Ratings</b>
                  </TableCell>
                  <TableCell>
                  {customer.tvratings}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Audience</b>
                  </TableCell>
                  <TableCell>
                  {customer.tvaudience}
                  </TableCell>
                </TableRow>                                                                

                <TableRow>
                  <TableCell>
                  <b>Markets</b>
                  </TableCell>

                  <TableCell>
                  {customer.tvmarkets}
                  </TableCell>
                </TableRow>                  
                </div>
              }

              </Table>
              </Segment>
              </Grid.Column>


              <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='purple' ribbon>
                Radio - Audience
              </Label>      
              <Table>
                {customer.radiobroadcasting==='no' &&
                <TableRow>
                  <TableCell>
                  No Radio Audience found
                  </TableCell>

                </TableRow>
                }

                {customer.radiobroadcasting==='yes' &&
                <div>
                <TableRow>
                  <TableCell>
                  <b>Number of radio broadcasted events</b>
                  </TableCell>
                  <TableCell>
                  {customer.radioevents}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Ratings</b>
                  </TableCell>
                  <TableCell>
                  {customer.radioratings}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Audience</b>
                  </TableCell>
                  <TableCell>
                  {customer.radioaudience}
                  </TableCell>
                </TableRow>                                                                

                <TableRow>
                  <TableCell>
                  <b>Markets</b>
                  </TableCell>

                  <TableCell>
                  {customer.radiomarkets}
                  </TableCell>
                </TableRow>                  
                </div>
              }

              </Table>
              </Segment>
              </Grid.Column>

              <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='purple' ribbon>
              Newspapers - Audience
              </Label>      
              <Table>
                {customer.newspaperscoverage==='no' &&
                <TableRow>
                  <TableCell>
                  No Newspapers Audience found
                  </TableCell>

                </TableRow>
                }

                {customer.newspaperscoverage==='yes' &&
                <div>
                <TableRow>
                  <TableCell>
                  <b>Number of newspapers</b>
                  </TableCell>
                  <TableCell>
                  {customer.newspapers}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Number of newspaper articles</b>
                  </TableCell>
                  <TableCell>
                  {customer.newsarticles}
                  </TableCell>                  
                </TableRow>                

                <TableRow>
                  <TableCell>
                  <b>Audience</b>
                  </TableCell>
                  <TableCell>
                  {customer.newshouseholds}
                  </TableCell>
                </TableRow>                                                                

                <TableRow>
                  <TableCell>
                  <b>Markets</b>
                  </TableCell>

                  <TableCell>
                  {customer.newsmarkets}
                  </TableCell>
                </TableRow>                  
                </div>
              }

              </Table>
              </Segment>
              </Grid.Column>              


              </Grid.Row>   


              <Grid.Row>
        <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='purple' ribbon>
                Magazines - Audience
              </Label>      
              <Table>
                {customer.magazinescoverage==='no' &&
                <TableRow>
                  <TableCell>
                  No Magazines Audience found
                  </TableCell>

                </TableRow>
                }

                {customer.magazinescoverage==='yes' &&
                <div>
                <TableRow>
                  <TableCell>
                  <b>Number of magazines</b>
                  </TableCell>
                  <TableCell>
                  {customer.magazinesnumber}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Number of articles</b>
                  </TableCell>
                  <TableCell>
                  {customer.magazinesarticles}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Audience</b>
                  </TableCell>
                  <TableCell>
                  {customer.magazinesaudience}
                  </TableCell>
                </TableRow>                                                                

                <TableRow>
                  <TableCell>
                  <b>Markets</b>
                  </TableCell>

                  <TableCell>
                  {customer.magazinesmarkets}
                  </TableCell>
                </TableRow>                  
                </div>
              }

              </Table>
              </Segment>
              </Grid.Column>


              <Grid.Column>
            <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='purple' ribbon>
                Digital Media - Audience
              </Label>      
              <Table>
                {customer.webcoverage==='no' &&
                <TableRow>
                  <TableCell>
                  No Digital Media Audience found
                  </TableCell>

                </TableRow>
                }

                {customer.webcoverage==='yes' &&
                <div>
                <TableRow>
                  <TableCell>
                  <b>Number of website</b>
                  </TableCell>
                  <TableCell>
                  {customer.webnumber}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Number of website articles</b>
                  </TableCell>
                  <TableCell>
                  {customer.webarticles}
                  </TableCell>                  
                </TableRow>

                <TableRow>
                  <TableCell>
                  <b>Websites analytics</b>
                  </TableCell>
                  <TableCell>
                  {customer.webanalytics}
                  </TableCell>
                </TableRow>                                                                

                <TableRow>
                  <TableCell>
                  <b>Markets</b>
                  </TableCell>

                  <TableCell>
                  {customer.webmarkets}
                  </TableCell>
                </TableRow>                  
                </div>
              }

              </Table>
              </Segment>
              </Grid.Column>              
              
              <GridColumn>
              <Segment>
              <Label style={{marginBottom:'1em'}} as='a' color='purple' ribbon>
                Members
              </Label>      
              <Table>
                {customer.members==='no' &&
                <TableRow>
                  <TableCell>
                  No Members found
                  </TableCell>

                </TableRow>
                }

                {customer.members==='yes' &&
                <div>
                <TableRow>
                  <TableCell>
                  <b>Number of members</b>
                  </TableCell>
                  <TableCell>
                  {customer.membersnumber}
                  </TableCell>                  
                </TableRow>
                </div>
              }

              </Table>
              </Segment>
              </GridColumn>

              </Grid.Row>                            



        <Grid.Row>
        <Grid.Column>

          </Grid.Column>     
          </Grid.Row>

          <GridRow>                     
                    <Grid.Column>
          <Segment>
              <Label as='a' color='brown' ribbon>
                Product
              </Label>    


              <Table color={'brown'}>


                {customer.scheduleonline &&
                <Table.Row>
                  <Table.Cell>
                  <Item>
                        <Item.Content>
                        <Item.Meta><b>Schedule URL</b></Item.Meta>
                        <Item.Description>
                        <a target="_blank" rel="noreferrer" href={formatURL(customer.scheduleonline)}>{customer.scheduleonline}</a>
                      </Item.Description>
                      </Item.Content>
                      </Item>                          
                    </Table.Cell>                    
                </Table.Row>
                }

              {!customer.scheduleonline &&                
                <Table.Row>
                  <Table.Cell>
                  <Item>
                        <Item.Content>
                        <Item.Meta><b>Schedule</b></Item.Meta>
                        <Item.Description>
                          <List>
                       {customer.myevents.map(e => <List.Item>  <List.Icon name='angle right' /> <List.Content> {e} </List.Content>  </List.Item>)} 
                      </List>
                      </Item.Description>
                      </Item.Content>
                      </Item>                          
                    </Table.Cell>                    
                </Table.Row>                
                }
            </Table>
            </Segment>
          </Grid.Column>





          <Grid.Column width={mywidth}>
          <Segment>
              <Label as='a' color='green' ribbon>
                Social Media
              </Label>    

              <Table>
                <Table.Row>
                  <Table.Cell>
                    <b>website</b>
                    </Table.Cell>
                    <Table.Cell>
                    <a target="_blank" rel="noreferrer" href={formatURL(customer.website)}>{customer.website}</a>
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>facebook</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.fb)}>{customer.fb}</a>
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>instagram</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.instagram)}>{customer.instagram}</a>
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>twitter</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.twitter)}>{customer.twitter}</a>                      
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>tiktok</b>
                    </Table.Cell>
                    <Table.Cell>
                      <a target="_blank" rel="noreferrer" href={formatURL(customer.tiktok)}>{customer.tiktok}</a>                      
                    </Table.Cell>                    
                </Table.Row>                
            </Table>
            </Segment>
          </Grid.Column>

          <Grid.Column width={mywidth}>
            <Segment>
              <Label as='a' color='blue' ribbon>
                Contact Details
              </Label>                  


              <Table>
                <Table.Row>
                  <Table.Cell>
                    <b>Contact Person</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactperson}
                    </Table.Cell>                    
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Email</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactemail}
                    </Table.Cell>                    
                </Table.Row>                


                <Table.Row>
                  <Table.Cell>
                    <b>Fixed Number</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactfixednumber}
                    </Table.Cell>                    
                </Table.Row>


                <Table.Row>
                  <Table.Cell>
                    <b>Mobile Number</b>
                    </Table.Cell>
                    <Table.Cell>
                      {customer.contactmobilenumber}
                    </Table.Cell>                    
                </Table.Row>
            </Table>
            </Segment>                                 

          </Grid.Column>          

          <Grid.Column width={mywidth}></Grid.Column>                    
        </GridRow>
      </Grid>

      </div>
  ) : <></>;
};

export default OrgCultureCard;